import { create } from "zustand";

const useOverlayStore = create((set) => ({
  active: false,
  content: null,
  setActive: (active) => {
    set({ active });
  },
  setContent: (content) => {
    set({ content });
  },
}));

export default useOverlayStore;
