import React from "react";
import cn from "classnames";

import * as styles from "./styles.module.scss";

const Button = ({ onClick = () => {}, target, text, theme = `dark` }) => {
  const buttonJsx = (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.container, styles[theme])}
    >
      <span className="caption">{text}</span>
    </button>
  );

  if (target) {
    return (
      <a href={target} rel="noopener noreferrer nofollow" target="_blank">
        {buttonJsx}
      </a>
    );
  }

  return buttonJsx;
};

export default Button;
