import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import gsap from "gsap";
import { Button, Footer, Grid } from "~/src/components";
import { useLanguage, useOverlay, useScroll } from "~/src/hooks";

import * as styles from "./styles.module.scss";

import ALT1 from "~/src/assets/images/ALT01.jpg";
import ALT2 from "~/src/assets/images/ALT02.jpg";
import ALT3 from "~/src/assets/images/ALT03.jpg";
import ALT4 from "~/src/assets/images/ALT04.jpg";

import DIR1 from "~/src/assets/images/DIR01.jpg";
import DIR2 from "~/src/assets/images/DIR02.jpg";
import DIR3 from "~/src/assets/images/DIR03.jpg";

import LIV1 from "~/src/assets/images/LIV01.jpg";
import LIV2 from "~/src/assets/images/LIV02.jpg";
import LIV3 from "~/src/assets/images/LIV03.jpg";
import LIV4 from "~/src/assets/images/LIV04.jpg";

const Feed = () => {
  const { language } = useLanguage();
  const { scrollY } = useScroll();

  const { setActive: setOverlayActive, setContent: setOverlayContent } =
    useOverlay();

  //

  const itemRefs = useRef([]);
  const [activeOverlay, setActiveOverlay] = useState(null);

  //

  // todo: replace with 3D
  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    scrollTo({ top: 0, behavior: `smooth` });

    const timeline = gsap.timeline();

    gsap.killTweensOf(itemRefs.current);

    items.forEach((item, index) => {
      // Set initial state
      gsap.set(itemRefs.current[index], { opacity: 0, y: 32 });

      // Add animation to the timeline
      timeline.to(itemRefs.current[index], {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: "power4.out",
        stagger: 0.2, // Stagger the animations for a nice effect
      });
    });
  }, [language]);

  //

  useEffect(() => {
    setActiveOverlay(null);
  }, [scrollY]);

  //

  const localizedItems = {
    en: [
      {
        title: `Livewire`,
        description: `Livewire is a game tech company who sees a future where gaming is home to the world’s most engaging cultural conversations.`,
        url: `https://www.livewire.group`,
        overlay: {
          text: `The design task was all based around flexibility, since the brand itself changes flexibly depending on projects. The website was created with minimum assets that the client could customise flexibly in the CMS for different needs throughout the website.`,
          credits: [
            {
              title: `Creative Director:`,
              name: `Danny Pemberton`,
            },
            {
              title: `Designers:`,
              name: `LAM Design team`,
            },
            {
              title: `Developers:`,
              name: `LAM Dev team`,
            },
          ],
        },
        images: [LIV1, LIV2, LIV3, LIV4],
        tags: [
          `Brand direction`,
          `website direction`,
          `website wireframing`,
          `ux design`,
        ],
      },
      {
        title: `Altr`,
        description: `The world’s first luxury collectible marketplace, transacted exclusively through cryptocurrency.`,
        url: `https://www.altr.trade`,
        overlay: {
          text: `The challenge was figuring out how to make the complex cryptocurrency transaction feel simple and intuitive, while maintaining the beautiful brand expression that delivers a refined and luxurious feel.`,
          credits: [
            {
              title: `Creative Director:`,
              name: `Danny Pemberton`,
            },
            {
              title: `Design Lead:`,
              name: `Dan Bisley`,
            },
            {
              title: `Designers:`,
              name: `LAM Design team`,
            },
            {
              title: `Developers:`,
              name: `LAM Dev team, ALTR Dev team`,
            },
          ],
        },
        images: [ALT1, ALT2, ALT3, ALT4],
        tags: [`Website direction`, `website wireframing`, `ux design`],
      },
      {
        title: `DIRTT`,
        description: `DIRTT offers services and products around their industrialised construction system.`,
        url: `https://www.dirtt.com`,
        overlay: {
          text: `Given the wide range of content that was to be displayed on the website, it was crucial to back up the wireframe with user testing. We ensured that the design and function was flexible enough for the client to load new products and content in the future.`,
          credits: [
            {
              title: `Creative Director:`,
              name: `Danny Pemberton`,
            },
            {
              title: `Designers:`,
              name: `LAM Design team`,
            },
            {
              title: `Developers:`,
              name: `LAM Dev team`,
            },
          ],
        },
        images: [DIR1, DIR2, DIR3],
        tags: [
          `Website design`,
          `website wireframing`,
          `ux design`,
          `brand development`,
        ],
      },
    ],
    jp: [
      {
        title: `Livewire`,
        description: `ゲームを通じて、わくわくすることを仕掛けていく会社。ゲームのユーザーだけでなく、社会全体を巻き込んでいくような企画を生み出しています。`,
        url: `https://www.livewire.group`,
        overlay: {
          text: `会社の特徴である「柔軟性」をどのように表現するかが課題でした。ウェブサイトは必要最小限のパーツで構成されつつも、クライアント自身が必要に応じてCMS内でカスタマイズできる仕様になっています。`,
          credits: [
            {
              title: `Creative Director:`,
              name: `Danny Pemberton`,
            },
            {
              title: `Designers:`,
              name: `LAM Design team`,
            },
            {
              title: `Developers:`,
              name: `LAM Dev team`,
            },
          ],
        },
        images: [LIV1, LIV2, LIV3, LIV4],
        tags: [
          `担当：ブランドディレクション`,
          `ウェブディレクション`,
          `ワイヤーフレーム設計`,
          `UXデザイン`,
        ],
      },
      {
        title: `Altr`,
        description: `仮想通貨で高級車や時計などを取引できるマーケットプレイス。商品を購入するだけでなく、投資として商品の一部を落札することなどもでき、仮想通貨ならではのサービスが魅力です。`,
        url: `https://www.altr.trade`,
        overlay: {
          text: `ブランドの持つ洗練された雰囲気を崩さずに、仮想通貨の複雑な購入プロセスをいかにユーザーにわかりやすくするか、試行錯誤しました。`,
          credits: [
            {
              title: `Creative Director:`,
              name: `Danny Pemberton`,
            },
            {
              title: `Design Lead:`,
              name: `Dan Bisley`,
            },
            {
              title: `Designers:`,
              name: `LAM Design team`,
            },
            {
              title: `Developers:`,
              name: `LAM Dev team, ALTR Dev team`,
            },
          ],
        },
        images: [ALT1, ALT2, ALT3, ALT4],
        tags: [
          `担当：ウェブディレクション`,
          `ワイヤーフレーム設計`,
          `UXデザイン`,
        ],
      },
      {
        title: `DIRTT`,
        description: `アメリカを拠点に、企業の内装を自社製の幅広いプロダクトでカスタムできるサービスを展開している会社。`,
        url: `https://www.dirtt.com`,
        overlay: {
          text: `様々なコンテンツを抱えるウェブサイトになることがわかっていたため、ユーザーテストを重ねてワイヤーフレームを設計しました。また、クライアントが継続して新商品やコンテンツを更新できるよう、デザイン面でも機能面でも工夫をしています。`,
          credits: [
            {
              title: `Creative Director:`,
              name: `Danny Pemberton`,
            },
            {
              title: `Designers:`,
              name: `LAM Design team`,
            },
            {
              title: `Developers:`,
              name: `LAM Dev team`,
            },
          ],
        },
        images: [DIR1, DIR2, DIR3],
        tags: [
          `担当：ウェブデザイン`,
          `ワイヤーフレーム設計`,
          `UXデザイン`,
          `ブランディング協力`,
        ],
      },
    ],
  };

  //

  const items = localizedItems?.[language] || localizedItems.en;

  //

  return (
    <section className={styles.container}>
      {/* <div className={styles.scrollArea}></div> */}

      <Grid>
        <div className={styles.scroll}>
          {items.map((item, index) => (
            <article
              ref={(el) => (itemRefs.current[index] = el)}
              key={item.title.toLowerCase()}
              className={cn(styles.item)}
            >
              <header className={styles.itemHeader}>
                <div className="h1">{item.title}</div>

                <button
                  type="button"
                  className={styles.detailsButton}
                  onClick={() => {
                    setOverlayContent(
                      <>
                        <p className={language === `en` ? `b2` : `b2j`}>
                          {localizedItems?.[language]?.[index]?.overlay?.text}
                        </p>

                        {localizedItems?.[language]?.[index]?.overlay
                          ?.credits?.[0] && (
                          <ul className={styles.overlayCredits}>
                            {localizedItems[language][
                              index
                            ].overlay.credits.map((credit, index) => (
                              <li key={`credit-${index}`} className="b2">
                                <h4 className="caption">{credit.title}</h4>
                                <p className="caption">{credit.name}</p>
                              </li>
                            ))}
                          </ul>
                        )}

                        <div className={styles.overlayButtons}>
                          <Button
                            target={item.url}
                            text="VISIT WEBSITE"
                            theme="light"
                          />

                          <button
                            type="button"
                            className={styles.overlayClose}
                            onClick={() => {
                              setOverlayActive(false);
                            }}
                          >
                            <h4 className="caption">[CLOSE X]</h4>
                          </button>
                        </div>
                      </>
                    );
                    setOverlayActive(true);
                  }}
                >
                  <div className="caption">[DETAILS]</div>
                </button>
              </header>

              <div className={styles.itemText}>
                <p className={language === `en` ? `b1` : `b1j`}>
                  {item.description}
                </p>

                <div className={styles.itemTags}>
                  {item.tags.map((tag, index) => (
                    <span
                      key={`${item.title.toLowerCase()}-tag-${index}`}
                      className={cn(`caption`, styles.itemTag)}
                    >
                      {tag}
                      {index !== item.tags.length - 1 ? `, ` : ``}
                    </span>
                  ))}
                </div>
              </div>

              <div className={styles.itemGallery}>
                {item.images.map((image, index) => (
                  <figure
                    key={`${item.title.toLowerCase()}-media-${index}`}
                    className={styles.itemImage}
                  >
                    <img src={image} alt={item.title} />
                  </figure>
                ))}
              </div>
            </article>
          ))}
        </div>
      </Grid>

      <Footer />
    </section>
  );
};

export default Feed;
