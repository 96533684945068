import useLanguageStore from "../store/useLanguageStore";

export const useLanguage = () => {
  const { language, setLanguage } = useLanguageStore((state: any) => state);

  return {
    language,
    setLanguage,
  };
};

export default useLanguage;
