import React, { useEffect, useRef } from "react";
import cn from "classnames";
import gsap from "gsap";
import { useScroll, useWindowDimensions } from "~/src/hooks";

import * as styles from "./styles.module.scss";

const ATF = () => {
  // const { isDesktop } = useWindowDimensions();
  const { scrollY } = useScroll();

  const charRefs = useRef([]);
  const logoRef = useRef();

  const text = `louie evans`;

  const chars = text.split(``);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    gsap.killTweensOf(charRefs.current);

    chars.forEach((char, index) => {
      gsap.to(charRefs.current[index], {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power4.out",
        delay: index * 0.05,
      });
    });
  }, []);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    const logo = logoRef.current;

    let y = scrollY * 0.35;

    if (y > 300) {
      y = 300;
    }

    gsap.killTweensOf(logo);

    gsap.to(logo, {
      y,
      duration: 0,
      filter: `blur(${y * 0.1}px)`,
      opacity: 1 - y * 0.003,
    });
  }, [scrollY]);

  return (
    <div className={styles.container}>
      <div ref={logoRef} className={styles.logo}>
        <h1 className={cn(`h1`, styles.logoText)}>
          {chars.map((letter, index) => (
            <span
              ref={(el) => (charRefs.current[index] = el)}
              key={`char-${index}`}
            >
              {letter}
            </span>
          ))}
        </h1>
      </div>
    </div>
  );
};

export default ATF;
