// extracted by mini-css-extract-plugin
export var active = "styles-module--active--bfa06";
export var badge = "styles-module--badge--e8716";
export var container = "styles-module--container--bf3af";
export var description = "styles-module--description--0e278";
export var desktop = "1340px";
export var giant = "2200px";
export var headerAppear = "styles-module--header-appear--236c6";
export var jobTitle = "styles-module--jobTitle--25df2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overlayButtons = "styles-module--overlayButtons--02231";
export var overlayClose = "styles-module--overlayClose--b9c21";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var toggle = "styles-module--toggle--325bd";
export var toggles = "styles-module--toggles--0c5ac";