// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--fb08f";
export var container = "styles-module--container--8ea9e";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--82c47";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var metatext = "styles-module--metatext--34032";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";