import React from "react";
import cn from "classnames";
import { Button, Grid } from "~/src/components";
import { useLanguage } from "~/src/hooks";

import * as styles from "./styles.module.scss";

const Footer = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <footer className={styles.container}>
      <Grid className={styles.grid}>
        <div className={styles.metatext}>
          <h4 className="caption">Website development by </h4>
          <h4 className="caption">
            <a
              href="https://www.danielcourtness.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              [DANIEL COURTNESS]
            </a>
          </h4>
        </div>

        <div className={styles.buttons}>
          <Button
            target="mailto:louie.evans.work@gmail.com"
            text={language === `en` ? `SEND A MESSAGE` : `メールを送る`}
          />
          <Button
            target="https://www.instagram.com/_louieevans/"
            text={language === `en` ? `STALK ON INSTA` : `インスタを覗く`}
          />
        </div>
      </Grid>
    </footer>
  );
};

export default Footer;
