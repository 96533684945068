// extracted by mini-css-extract-plugin
export var active = "styles-module--active--86709";
export var container = "styles-module--container--f993b";
export var desktop = "1340px";
export var detailsButton = "styles-module--detailsButton--265d0";
export var feedAppear = "styles-module--feed-appear--d8918";
export var giant = "2200px";
export var item = "styles-module--item--fcc8d";
export var itemGallery = "styles-module--itemGallery--76978";
export var itemHeader = "styles-module--itemHeader--4cd0b";
export var itemImage = "styles-module--itemImage--d6909";
export var itemTag = "styles-module--itemTag--a818d";
export var itemTags = "styles-module--itemTags--fd207";
export var itemText = "styles-module--itemText--e4691";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var overlayButtons = "styles-module--overlayButtons--0ffde";
export var overlayCredits = "styles-module--overlayCredits--6ca84";
export var scroll = "styles-module--scroll--d6fbc";
export var scrollArea = "styles-module--scrollArea--14966";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";