import { create } from "zustand";

const useLanguageStore = create((set) => ({
  language: `en`,
  setLanguage: (language) => {
    set({ language });
  },
}));

export default useLanguageStore;
