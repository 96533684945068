import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import FacebookSEO from "./Facebook";
import TwitterSEO from "./Twitter";

const SEO = ({ pageTitle, description, keywords }) => {
  const keywordsText = keywords ? keywords.join(", ") : null;

  return (
    <>
      <title>{pageTitle}</title>

      <link rel="icon" type="image/png" href={`/images/favicon.png`} />
      <link rel="canonical" href={`https://www.danielcourtness.com/`} />
      <meta name="description" content={description} />

      {keywordsText && <meta name="keywords" content={keywordsText} />}

      <FacebookSEO
        description={description}
        ogImageUrl={`/images/opengraph.jpg`}
        siteUrl={`https://www.danielcourtness.com/`}
        title={pageTitle}
      />

      <TwitterSEO
        description={description}
        ogImageUrl={`/images/opengraph.jpg`}
        title={pageTitle}
      />
    </>
  );
};

export default SEO;
