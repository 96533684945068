import React, { useEffect, useRef } from "react";
import cn from "classnames";
import gsap from "gsap";
import { Button, Grid } from "~/src/components";
import { useLanguage, useOverlay } from "~/src/hooks";

import * as styles from "./styles.module.scss";

const Header = () => {
  const { language, setLanguage } = useLanguage();

  const {
    active: overlayActive,
    setActive: setOverlayActive,
    setContent: setOverlayContent,
  } = useOverlay();

  //

  const containerRef = useRef();

  //

  useEffect(() => {
    const container = containerRef.current;

    gsap.killTweensOf(container);

    gsap.to(container, {
      duration: 0.75,
      filter: overlayActive ? `blur(20px)` : `blur(0px)`,
      ease: "power4.out",
    });
  }, [overlayActive]);

  //

  const overlayContent = {
    en: (
      <>
        <p className="b2">
          Louie Evans is a Digital Designer with a Japanese, British and
          Australian ethnicity. Received a Bachelor of Communication Design from
          Monash University.
        </p>

        <br />

        <p className="b2">
          I’m currently a Digital Designer at{` `}
          <a
            href="https://www.loveandmoney.agency"
            rel="noopener noreferrer"
            target="_blank"
          >
            Love+Money Agency
          </a>
          . My focus is on understanding client needs for digital outcomes
          through wireframes and user discoveries, while designing outcomes that
          aims to elevate the{" "}
          <a
            href="https://www.youtube.com/watch?v=iaINP04-VvA&ab_channel=Figma"
            rel="noopener noreferrer"
            target="_blank"
          >
            Brand Meme
          </a>
          .
        </p>

        <div className={styles.overlayButtons}>
          <Button
            target="mailto:louie.evans.work@gmail.com"
            text="SEND A MESSAGE"
            theme="light"
          />
          <Button
            target="https://www.instagram.com/_louieevans/"
            text="STALK ON INSTA"
            theme="light"
          />
        </div>

        <button
          type="button"
          className={styles.overlayClose}
          onClick={() => {
            setOverlayActive(false);
          }}
        >
          <h4 className="caption">[CLOSE X]</h4>
        </button>
      </>
    ),
    jp: (
      <>
        <p className="b2j">エバンス瑠伊</p>

        <br />

        <p className="b2j">
          日本生まれ、オーストラリア育ちのデジタルデザイナー。モナッシュ大学コミュニケーションデザイン学部卒業。
        </p>

        <br />

        <p className="b2j">
          現在は
          <a
            href="https://www.loveandmoney.agency"
            rel="noopener noreferrer"
            target="_blank"
          >
            Love+Money Agency
          </a>
          に所属。主にウェブサイトやデジタル分野のデザインを担当しており、ユーザーリサーチやワイヤーフレーム設計から、UX/UIまで一貫して担う。
        </p>

        <br />

        <p className="b2j">
          ブランディングをただウェブサイトに反映するのではなく、ウェブ上でしかできないことを盛り込み、ブランディングに深みを持たせることを意識している。
        </p>

        <div className={styles.overlayButtons}>
          <Button
            target="mailto:louie.evans.work@gmail.com"
            text="メールを送る"
            theme="light"
          />
          <Button
            target="https://www.instagram.com/_louieevans/"
            text="インスタを覗く"
            theme="light"
          />
        </div>

        <button
          type="button"
          className={styles.overlayClose}
          onClick={() => {
            setOverlayActive(false);
          }}
        >
          <h4 className="caption">[CLOSE X]</h4>
        </button>
      </>
    ),
  };

  return (
    <header ref={containerRef} className={styles.container}>
      <Grid>
        <div className={styles.description}>
          <h3 className={cn(`caption`, styles.jobTitle)}>DIGITAL DESIGNER</h3>

          <button
            type="button"
            onClick={() => {
              setOverlayContent(overlayContent?.[language]);
              setOverlayActive(true);
            }}
          >
            <h4 className="caption">[ABOUT]</h4>
          </button>
        </div>

        <div className={styles.toggles}>
          <button
            type="button"
            className={cn(styles.toggle, {
              [styles.active]: language === `en`,
            })}
            onClick={() => setLanguage(`en`)}
          >
            <div className={styles.badge} />
            <span className="caption">[EN]</span>
          </button>

          <button
            type="button"
            className={cn(styles.toggle, {
              [styles.active]: language === `jp`,
            })}
            onClick={() => setLanguage(`jp`)}
          >
            <div className={styles.badge} />
            <span className="caption">[JP]</span>
          </button>
        </div>
      </Grid>
    </header>
  );
};

export default Header;
