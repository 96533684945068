import { useEffect } from "react";
import useOverlayStore from "../store/useOverlayStore";
import { useKeyPress } from "~/src/hooks";

export const useOverlay = () => {
  const { active, setActive, content, setContent } = useOverlayStore(
    (state: any) => state
  );

  const escKeyPressed = useKeyPress("Escape");

  useEffect(() => {
    if (escKeyPressed) {
      setActive(false);
    }
  }, [escKeyPressed]);

  useEffect(() => {
    if (!active) {
      setTimeout(() => {
        setContent(null);
      }, 500);
    }
  }, [active]);

  return {
    active,
    setActive,
    content,
    setContent,
  };
};

export default useOverlay;
