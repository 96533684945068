import React from "react";
import cn from "classnames";
import { Helmet } from "react-helmet";

import { Header, Overlay } from "~/src/components";

import "modern-normalize/modern-normalize.css";
import "../styles/global.css";
import "../styles/colors.scss";
import "../styles/fonts.scss";

import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => (
  <>
    <Helmet>
      <script type="application/javascript">
        {`
      {
        (function(d) {
          var config = {
            kitId: '${process.env.GATSBY_ADOBE_FONT_PROJECT_ID}',
            scriptTimeout: 3000,
            async: true
          },
          h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
        })(document);
      }
    `}
      </script>
    </Helmet>

    {/* // */}

    <Overlay />

    <Header />

    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/odp2twe.css" />
    </Helmet>

    <div className={cn(styles.container, className)}>{children}</div>
  </>
);

export default Layout;
