import React from "react";
import cn from "classnames";
import { Grid } from "~/src/components";
import { useOverlay } from "~/src/hooks";

import * as styles from "./styles.module.scss";

const Overlay = () => {
  const { active, content } = useOverlay();

  return (
    <div className={cn(styles.container, { [styles.active]: active })}>
      <Grid className={styles.grid}>
        <div className={styles.content}>{content || <></>}</div>
      </Grid>
    </div>
  );
};

export default Overlay;
